<template>
  <div id="login-form">
    <alert-message :message="errorMessage" classes="py-3 mb-6" />
    <div id="login-form__wrapper">
      <v-form
        v-model="isFormValid"
        ref="loginForm"
        @submit.prevent="submitHandler"
      >
        <div class="form-group pb-1">
          <v-text-field
            outlined
            validate-on-blur
            class="required"
            label="Email Address"
            v-model="formData.email"
            :rules="[required('Email address'), validateEmail]"
          />
        </div>
        <div class="form-group">
          <password-input-field
            :password="formData.password"
            :rules="[required('', 'Please enter a password.')]"
            @update-password="formData.password = $event"
          />
        </div>
        <div class="form-group pt-1">
          <v-btn
            block
            large
            type="submit"
            color="primary"
            :loading="isLoading"
            :disabled="!isFormValid"
            class="button font-weight-bold"
          >
            Login
          </v-btn>
          <div
            class="text-capitalize text-center font-weight-bold text--secondary caption py-2"
          >
            OR
          </div>
          <div class="form-group">
            <v-btn
              block
              large
              outlined
              type="button"
              color="secondary"
              id="google-sign-in-btn"
              class="button font-weight-bold"
              @click="signInUserWithGooglePopUp"
            >
              <img
                class="pr-3"
                width="30px"
                height="30px"
                alt="Google icon"
                id="google-sign-in-login"
                :src="$images.googleIcon"
              />
              Sign in with google
            </v-btn>
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { PasswordInputField, AlertMessage } from "@/components/shared";
import { required, email as validateEmail } from "@/validators";
import { AUTH_GIP_ERROR_CODES } from "@/constants";

/**
 * Auth error codes
 */
const authErrorCodes = Object.values(AUTH_GIP_ERROR_CODES);
authErrorCodes.length = 2;
/**
 * Login form
 */
export default {
  name: "LoginForm",
  /**
   * ---------- Components ------------
   */
  components: {
    PasswordInputField,
    AlertMessage,
  },
  /**
   * ---------- Data properties ------------
   */
  data() {
    return {
      isFormValid: false,
      showPassword: false,
      formData: { email: "", password: "" },
      isLoading: false,
      errorMessage: "",
    };
  },
  /**
   * ---------- Computed properties ------------
   */
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
    }),
  },
  /**
   * ---------- Methods ------------
   */
  methods: {
    required,
    validateEmail,
    ...mapActions({
      signInUser: "auth/signInUser",
      signInUserWithGoogle: "auth/signInUserWithGoogle",
    }),
    /**
     * Handles login form submittion
     */
    submitHandler() {
      if (!this.isFormValid) {
        return this.$refs.loginForm.validate();
      }
      this.signInUserApi();
    },
    /**
     * Sends a post request to login the user
     */
    async signInUserApi() {
      try {
        this.setIsloading(true);

        await this.signInUser(this.formData);
      } catch (error) {
        const { code, message } = error;

        if (authErrorCodes.includes(code)) {
          this.errorMessage = this.$dictionary.app.loginForm.invalidUserMsg;
        } else {
          this.errorMessage = message;
        }
      } finally {
        this.setIsloading(false);
      }
    },
    /**
     * Sets isloading property to display the loader
     */
    setIsloading(val) {
      this.isLoading = val;
    },
    /**
     * Sign in user with google auth provider popup
     */
    async signInUserWithGooglePopUp() {
      try {
        this.setIsloading(true);
        await this.signInUserWithGoogle();
      } catch (error) {
        this.errorMessage = this.$errorMessage(error);
      } finally {
        this.setIsloading(false);
      }
    },
  },
};
</script>
